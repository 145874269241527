/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Facebook from './facebook'
import Instagram from './instagram'
import { Link } from 'gatsby'
import Copyright from './copyright'
import Youtube from './youtube'

import './layout.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1160,
        }}
      >
        <main>{children}</main>
      </div>
      <footer>
        <div className="footer-row">
          <Copyright /> {new Date().getFullYear()}
          {` ${data.site.siteMetadata?.title}`}
        </div>
        <div className="footer-row">
          <span className="footer-spacer">
            <Link to="https://www.facebook.com/cory.bott.330">
              <Facebook />
            </Link>
          </span>
          <span className="footer-spacer">
            <Link to="https://instagram.com/rockymtntorch?igshid=fcbrjskc3kfb">
              <Instagram />
            </Link>
          </span>
          <span className="footer-spacer">
            <Link to="https://www.youtube.com/channel/UCjkjPiwOBzsMEI2DCZg4Ayw">
              <Youtube />
            </Link>
          </span>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
