import React from 'react'
import { Link } from 'gatsby'
import Media from 'react-media'

import Menu from './menu'

import './links.scss'

const links = [
  { label: 'Home', path: '/' },
  { label: 'About', path: '/about' },
  { label: 'Contact', path: '/contact' },
  { label: 'Posts', path: '/posts' },
  { label: 'For Sale', path: '/sale' },
]

function Links() {
  const renderLinks = () =>
    links.map(link => (
      <div className="link-block">
        <Link to={link.path}>{link.label}</Link>
      </div>
    ))

  return (
    <Media query={{ maxWidth: 669 }}>
      {matches =>
        matches ? (
          <div>
            <Menu links={renderLinks()} />
          </div>
        ) : (
          <div className="rm-header">{renderLinks()}</div>
        )
      }
    </Media>
  )
}

export default Links
