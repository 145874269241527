import { Link } from 'gatsby'
import React from 'react'
import Logo from '../images/Rocky Mountain_finalLOGO-01.png'
import Links from './links'

const Header = () => (
  <header
    style={{
      display: `flex`,
      flexDirection: `row`,
      alignItems: 'center',
      justifyContent: 'space-between',
      height: `fit-content`,
      padding: `10px`,
      border: 'solid 1px white',
      backgroundColor: '#f6934b',
    }}
  >
    <Link to="/">
      <img
        src={Logo}
        alt="rocky mountain torch logo"
        height={120}
        width={240}
      />
    </Link>
    <Links />
  </header>
)

export default Header
