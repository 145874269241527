import React, { useState } from 'react'
import enhanceWithClickOutside from 'react-click-outside'

class Menu extends React.Component {
  state = {
    isOpen: false,
  }

  setIsOpen = isOpen => {
    this.setState({ isOpen })
  }

  handleClickOutside() {
    this.setIsOpen(false)
  }

  render() {
    return (
      <div style={{ position: 'relative', cursor: 'pointer' }}>
        <svg
          style={{ width: '34px', height: '34px' }}
          viewBox="0 0 24 24"
          onClick={() => this.setIsOpen(!this.state.isOpen)}
        >
          <path
            fill="currentColor"
            d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
          />
        </svg>
        {this.state.isOpen ? (
          <div className="rm-header">{this.props.links}</div>
        ) : null}
      </div>
    )
  }
}

export default enhanceWithClickOutside(Menu)
